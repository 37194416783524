import './theme-nexi-visa-creditagricole.scss'

const updateHeader = () => {
    const navbar = document.querySelector("body .navbar");
    navbar.classList.remove('fixed-top');

    const img = document.createElement("img");
    img.src = "https://sj-solutions.s3.eu-west-1.amazonaws.com/470821/reward/creditagricole/Img_CreditAgricole.png";
    img.classList.add('img-fluid');

    const slider = document.createElement('div');
    slider.classList.add('slider');
    slider.classList.add('d-lg-flex');
    slider.classList.add('p-lg-5');

    const sliderContent = document.createElement('div');
    sliderContent.classList.add('slider__content');
    sliderContent.classList.add('d-lg-flex');
    sliderContent.classList.add('align-items-center');

    sliderContent.innerHTML = '<div class="slider__content_textwrap"> <p class="slider__content_small_text">CONCORSO A PREMI</p><p>Riparti alla grande con la tua carta Crédit Agricole Visa.</p></div>';

    slider.append(sliderContent, img);

    navbar.insertAdjacentElement('afterend', slider);

    //$('img[alt="logo-right"]').css('width', '200px');

    const buttonLogin = document.querySelector("body .button-login");
    if (buttonLogin) {
        // Leggi l'attributo "style" esistente, se presente
        const existingStyle = buttonLogin.getAttribute('style') || '';

        // Aggiungi il padding e il font-size all'attributo "style" esistente
        const newStyle = existingStyle + 'padding: 1rem 3rem; font-size: 1.5rem';

        // Imposta il nuovo valore dell'attributo "style"
        buttonLogin.setAttribute('style', newStyle);
    }
}

const init = () => {
    updateHeader();
};

window.addEventListener('load', () => init() );